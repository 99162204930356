// // @flow
import { identifyUserWithCallback, trackSegment } from './segment'
import type {
  PageSharedType,
  SubscriptionLengthType,
  PromoCodeEventType,
  TrackPlayerAnalyticsObjectType,
  QuizType, UserCancellationSurveyAnswerType
} from '../flowTypes'
import { sha256, trackAnnualPurchaseSnapChat, trackSignUpSnapChat } from './snapchat'
import { reportError } from './bugSnag'
import { currentUser } from './firebaseAuth'
import { logEvent, getAnalytics, setUserId } from 'firebase/analytics'
import { getRemoteConfigString } from './firebaseRemoteConfig'

export function sanitizeUrlWithEmailPass (): void {
  if (typeof window === 'undefined') {
    return
  }
  let url
  if ((window.location || {}).href.includes('email=')) {
    if (!url) {
      url = window.location.href
    }
    url = url.replace(/(?:\?|&|;)(([email=]+)=([^&|;]+))/i, '')
  }

  if ((window.location || {}).href.includes('password=')) {
    if (!url) {
      url = window.location.href
    }
    url = url.replace(/(?:\?|&|;)(([password=]+)=([^&|;]+))/i, '')
  }

  if (url) {
    window.history.replaceState({}, document.title, url)
  }
}

export function getCookie (cname: string): string | null {
  try {
    const name = cname + '='
    const decodedCookie = decodeURIComponent(document.cookie)
    const ca = decodedCookie.split(';')
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i]
      while (c.charAt(0) === ' ') {
        c = c.substring(1)
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length)
      }
    }
  } catch (e) {
  }

  return null
}

function getFacebookCookies (): { _fbp: string | null, _fbc: string | null } {
  return {
    _fbp: getCookie('_fbp'),
    _fbc: getCookie('_fbc')
  }
}

export const logFBEvent = (eventName: string, eventProps?: any, retryTime: number = 0): void => {
  if (!window?.firebase?.name) {
    if (retryTime < 50) {
      setTimeout((): void => {
        logFBEvent(eventName, eventProps, ++retryTime)
      }, 1000)
    }
  }

  try {
    // if (isDev) {
    //   return console.log('TEST logFBEvent', 'eventName:', eventName, 'eventProps:', eventProps)
    // }
    sanitizeUrlWithEmailPass()
    const user = currentUser()
    const featureAPITestGroup = getRemoteConfigString('featureLongPreviewPlay')
    if (featureAPITestGroup) {
      if (!eventProps) {
        eventProps = {}
      }
      eventProps.featureAPITestGroup = featureAPITestGroup
    }

    if (user) {
      setUserId(getAnalytics(), user.id)
    } else if (retryTime < 15) {
      setTimeout((): void => {
        logFBEvent(eventName, eventProps, ++retryTime)
      }, 1000)
    }

    if (
      !(window.location || {}).href.includes('email=') &&
      !(window.location || {}).href.includes('password=')
    ) {
      const analytics = getAnalytics(window.firebase)
      logEvent(analytics, eventName, eventProps)
    } else {
      reportError(new Error('Firebase not eventing due to email or password'))
    }
  } catch (error) {
    console.log(error)
  }
}

export const logTikTokEvent = (label: 'AddPaymentInfo' |
  'AddToCart' |
  'ClickButton' |
  'CompletePayment' |
  'CompleteRegistration' |
  'Download' |
  'InitiateCheckout' |
  'PlaceAnOrder' |
  'ViewContent'
): void => {
  if (typeof window !== 'undefined') {
    sanitizeUrlWithEmailPass()
    if (window.ttq) {
      try {
        const user = currentUser()
        if (!!user?.email && !!user?.id) {
          // $FlowFixMe
          const userEmail = user.email
          sha256(userEmail).then((email: string): void => {
            // $FlowFixMe
            window.ttq.identify({ email, external_id: user.id })
            window.ttq.track(label)
          })
        } else {
          window.ttq.track(label)
        }
      } catch (e) {
        reportError(e)
      }
    }
  }
}

export const events = {
  rated_track: (trackId: string, value: string): void => {
    logFBEvent('rated_track', {
      trackId,
      value
    })
  },
  user_selected_annual_subscription: (params: {
    stripeToken: string
  }): void => {
    const { stripeToken } = params
    const newObj = Object.assign({}, params)
    delete newObj.stripeToken
    newObj.facebook = getFacebookCookies()
    logFBEvent('user_selected_annual_subscription', newObj)
    trackSegment('Annual Subscription Selected', newObj, true)
    trackAnnualPurchaseSnapChat(stripeToken)
    logTikTokEvent('CompletePayment')
  },
  user_selected_monthly_subscription: (params: {}): void => {
    const newObj = Object.assign({}, params)
    newObj.facebook = getFacebookCookies()
    logFBEvent('user_selected_monthly_subscription', params)
    trackSegment('Monthly Subscription Selected', newObj, true)
    logTikTokEvent('CompletePayment')
  },
  followed_category: (params: {}): void =>
    logFBEvent('followed_category', params),
  unfollowed_category: (params: {}): void =>
    logFBEvent('unfollowed_category', params),
  first_category_followed: (params: {}): void =>
    logFBEvent('first_category_followed', params),
  paywall_dismiss: (): void => {
    logFBEvent('paywall_dismiss')
  },
  paywall_show: (): void => {
    logFBEvent('paywall_show')
  },
  track_played: (trackData: TrackPlayerAnalyticsObjectType): void => {
    logFBEvent('playback_state_change', {
      ...trackData,
      state: 'playing'
    })
  },
  track_paused: (trackData: TrackPlayerAnalyticsObjectType): void => {
    logFBEvent('playback_state_change', {
      ...trackData,
      state: 'paused'
    })
  },
  track_seek_completed: (trackData: TrackPlayerAnalyticsObjectType): void => {
    // do nothing
  },
  track_completed: (trackId: string): void => {
    logFBEvent('completed_track', { trackId })
  },
  track_started: (trackId: string): void => {
    logFBEvent('playlist_started', { trackId })
  },
  track_preview_play: (trackId: string): void => {
    logFBEvent('track_preview_play', { trackId })
    logTikTokEvent('ViewContent')
  },
  track_buffer_started: (trackData: TrackPlayerAnalyticsObjectType): void => {
    // do nothing
  },
  track_buffer_completed: (trackData: TrackPlayerAnalyticsObjectType): void => {
    // do nothing
  },
  signed_in: (
    email: string,
    provider: 'email' | 'apple' | 'facebook' | 'google' | 'oauth'
  ): void => {
    logFBEvent(
      'signed_in',
      {
        email,
        provider
      }
    )
    const event: { [key: string]: string } = {
      email,
      provider,
      platform: 'web'
    }
    if (window) {
      event.url = (window.location || {}).href
    }
    trackSegment('Signed In', event, true)
    logTikTokEvent('CompleteRegistration')
  },
  signed_out: (): void => {
    logFBEvent('signed_out')
    trackSegment('Signed Out')
  },
  signed_up: (
    email: string,
    provider: 'email' | 'apple' | 'facebook' | 'google' | 'oauth'
  ): void => {
    logFBEvent(
      'signed_up',
      {
        email,
        provider
      }
    )
    const event: { [key: string]: any } = {
      email,
      provider,
      platform: 'web',
      facebook: getFacebookCookies()
    }
    if (window) {
      event.url = (window.location || {}).href
    }
    trackSegment('Signed Up', event, true)
    trackSignUpSnapChat()
    logTikTokEvent('CompleteRegistration')
  },

  removed_from_favorites: (trackData: TrackPlayerAnalyticsObjectType): void => {
    logFBEvent('removed_from_favorites', trackData)
  },
  added_to_favorites: (trackData: TrackPlayerAnalyticsObjectType): void => {
    logFBEvent('added_to_favorites', trackData)
  },
  opened_unlock_cta: (): void => {
    logFBEvent('opened_unlock_cta')
  },
  page_error_log: (path: string): void => {
    // do nothing
  },
  updateEmail: (): void => {
    logFBEvent('update_email_address')
  },
  addedEmail: (): void => {
    logFBEvent('added_email_address')
  },
  updatePassword: (): void => {
    logFBEvent('update_password')
  },
  deleteAccount: (): void => {
    logFBEvent('delete_account')
  },
  track_opened: (trackData: TrackPlayerAnalyticsObjectType): void => {
    logFBEvent('track_opened', trackData)
  },
  cta_button_clicked: (params: {
    cta_name: string,
    url: string,
    [key: string]: any
  }): void => {
    logFBEvent('cta_button_clicked', params)
  },
  nav_link_clicked: (params: { nav_link_name: string, url: string }): void => {
    logFBEvent('navigation_link_clicked', params)
  },
  search_term_selected: (params: {}): void => {
    logFBEvent('search_term_selected', params)
  },
  search_result_selected: (trackData: TrackPlayerAnalyticsObjectType): void => {
    logFBEvent('search_result_selected', trackData)
  },
  filtered_tag_selected: (params: {}): void => {
    logFBEvent('filtered_tag_selected', params)
  },
  filtered_tag_result_selected: (
    trackData: TrackPlayerAnalyticsObjectType
  ): void => {
    logFBEvent('filtered_tag_result_selected', trackData)
  },
  deep_link_opened: (params: {}): void => {
    logFBEvent('deep_link_opened', params)
  },
  promo_code_applied: (params: PromoCodeEventType): void => {
    logFBEvent('promo_code_applied', params)
    trackSegment('Promo Code Applied', params)
    logTikTokEvent('AddToCart')
  },
  promo_code_denied: (params: PromoCodeEventType): void => {
    logFBEvent('promo_code_denied', params)
  },
  refer_friend_modal_dismissed: (): void => {
    logFBEvent('refer_friend_modal_viewed')
    logFBEvent('refer_friend_modal_dismissed')
  },
  promo_account_created: (userId: string, email: string, params: {}): void => {
    logFBEvent('promo_account_created', params)
    identifyUserWithCallback(userId, email, (): void => {
      trackSegment('Promo Account Created', params)
    })
  },
  promo_subscription_started: (params: {
    subscription_type: ?SubscriptionLengthType,
    promo: string,
    promotion_id: string,
    reward_type: string,
    product_id: string,
    trial: boolean
  }): void => {
    logFBEvent('promo_subscription_started', params)
    trackSegment('Promo Subscription Started', params)
  },
  page_shared: (params: PageSharedType): void => {
  },
  payment_method_updated: (): void => {
    logFBEvent('payment_method_updated')
  },
  quiz_answer_selected: (params: {}): void => {
    logFBEvent('quiz_answer_selected', params)
  },
  quiz_results: (params: {
    quizType: QuizType,
    results: {},
    genderPreference: string
  }): void => {
    logFBEvent('quiz_results', params)
  },
  conversion_quiz_completed: (params: {
    hunk_id: string,
    hunk_name: string,
    sexual_preference_answer: string
  }): void => {
    trackSegment('Conversion Quiz Completed', params)
  },
  trope_conversion_quiz_completed: (params: {
    series_id: string,
    series_name: string,
    sexual_preference_answer: string
  }): void => {
    trackSegment('Series Conversion Quiz Completed', params)
  },
  fantasy_conversion_quiz_completed: (params: {
    series_id: string,
    series_name: string,
    sexual_preference_answer: string
  }): void => {
    trackSegment('Fantasy Conversion Quiz Completed', params)
  },
  opened_series: (params: {}): void => {
    logFBEvent('opened_series', params)
  },
  opened_category: (params: {}): void => {
    logFBEvent('opened_category', params)
  },
  clicked_download_dipsea_cta: (): void => {
    logFBEvent('clicked_download_dipsea_cta', {})
    logTikTokEvent('Download')
  },
  a_b_test_flow: (params: {}): void => {
    logFBEvent('a_b_test_flow', params)
  },
  purchasedGiftCode: (params: {
    giftCode: string,
    promoCode?: string,
    giftType?: string,
    price?: number,
    discount?: string
}): void => {
    logFBEvent('purchased_gift_code', params)
  },
  redeemCode: (properties: { gift_code: string, redeemed_at: number }): void =>
    trackSegment('Gift Redeemed', properties),
  giveAwayCodeRedeemed: (properties: { code: string, length: string }): void =>
    trackSegment('Give Away Code Redeemed', properties),
  giveAwayCodeError: (properties: { code: string, length: string, error: string }): void =>
    trackSegment('Give Away Code Error', properties),
  blog_email_capture: (email: string, page: string): void => {
    logFBEvent('blog_email_capture', { page })
    identifyUserWithCallback(undefined, email, (): void => {
      trackSegment('Blog Email Capture', { email, page })
    })
  },
  blog_email_shown: (page: string): void => {
    logFBEvent('blog_email_shown', { page })
  },
  user_cancelled_subscription: (params: {user_id: string, stripe_key: string}): void => {
    logFBEvent('user_cancelled_subscription', params)
  },
  user_answered_cancellation_survey: (params: UserCancellationSurveyAnswerType): void => {
    trackSegment('User Answered Cancellation Survey', params)
  },
  user_applied_discount_to_subscription: (params: {promotion_id: 'fiftypercentoff' | '30percentoff', promotion_name: string}): void => {
    logFBEvent('promo_code_applied', params)
    trackSegment('Promo Code Applied', params)
  },
  refund_request: (amountToRefund: number): void => {
    logFBEvent('refund_request', { amountToRefund })
    trackSegment('Refund Requested', { amountToRefund })
  },
  modal_shown: (type: string, id?: string): void => logFBEvent('modal_shown', { type, id }),
  modal_dismissed: (type: string): void => logFBEvent('modal_dismissed', { type })
}
