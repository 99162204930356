// @flow
import * as React from 'react'
import styled from 'styled-components'
import { colors } from './colors'
import { config } from 'react-awesome-styled-grid'
import { OutlineOnFocusVisible } from './buttons'
import { DipseaContentfulAssets } from '@components/shared/contentfulAssets'
import { FadeInImage } from '@components/shared/FadeInImage'

type PropsType = {
  bgGradient?: boolean,
  children: React.Node,
  isVisible: boolean,
  invertColor?: boolean,
  toggleModalFunction?: () => void,
  disableFullScreenMobile?: boolean,
  notificationCompensation?: boolean,
  maxWidth?: number,
  overflow?: string,
  backButtonClicked?: () => void,
  isFullScreen?: boolean,
  closeButtonHeight?: number,
  modalCardBackgroundColor?: string
}

type IconPropsType = {
  type: 'back' | 'x',
  size?: number,
  color?: string,
  backgroundColor?: string,
  fillOpacity?: string
}

function Icon (props: IconPropsType): React.Node {
  if (props.type === 'back') {
    return (
      <svg
        width={props.size || '100%'}
        height={props.size || '100%'}
        viewBox='0 0 16 30'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M14.4616 29.9999C14.0619 29.9999 13.6622 29.8428 13.361 29.5293L0.437878 16.0918C-0.146122 15.4846 -0.146122 14.5153 0.437878 13.9081L13.361 0.47057C13.9548 -0.147243 14.9293 -0.157555 15.5366 0.444632C16.1443 1.04776 16.156 2.03713 15.5622 2.65432L3.68895 14.9999L15.5622 27.3456C16.156 27.9628 16.1443 28.9521 15.5366 29.5553C15.2373 29.8518 14.8493 29.9999 14.4616 29.9999Z'
          fill={props.color || colors.black}
        />
      </svg>
    )
  } else {
    return (
      <svg
        width={props.size || 19}
        height={props.size || 19}
        viewBox='0 0 19 19'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M0.292893 0.292893C0.683417 -0.0976311 1.31658 -0.0976311 1.70711 0.292893L9.5 8.08579L17.2929 0.292893C17.6834 -0.0976311 18.3166 -0.0976311 18.7071 0.292893C19.0976 0.683417 19.0976 1.31658 18.7071 1.70711L10.9142 9.5L18.7071 17.2929C19.0976 17.6834 19.0976 18.3166 18.7071 18.7071C18.3166 19.0976 17.6834 19.0976 17.2929 18.7071L9.5 10.9142L1.70711 18.7071C1.31658 19.0976 0.683417 19.0976 0.292893 18.7071C-0.0976311 18.3166 -0.0976311 17.6834 0.292893 17.2929L8.08579 9.5L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683417 0.292893 0.292893Z'
          fill={props.color || colors.white}
        />
      </svg>
    )
  }
}

function Modal (props: PropsType): React.Node {
  const modalRef = React.useRef(null)
  React.useEffect((): any => {
    const possibleElements = (modalRef.current || {}).querySelectorAll(
      'a[href], button, textarea, input, select, [role="button"]'
    )
    let firstFocus = possibleElements.length >= 0 ? possibleElements[0] : null
    if (possibleElements.length >= 0) {
      const firstFocusableElement = possibleElements[0]
      if (
        firstFocusableElement &&
        firstFocusableElement.ariaLabel === 'Close'
      ) {
        firstFocus = possibleElements[1] || null
      }
    }
    if (firstFocus) {
      firstFocus.focus()
    }
    document.addEventListener('keydown', keyListener)
    return (): any => document.removeEventListener('keydown', keyListener)
  }, [])

  const handleTabKey = (e: KeyboardEvent): any => {
    const focusableModalElements = (modalRef.current || {}).querySelectorAll(
      'a[href], button, textarea, input, select, [role="button"], div.StripeElement'
    )
    const firstElement = focusableModalElements[0]
    const lastElement =
      focusableModalElements[focusableModalElements.length - 1]

    if (!e.shiftKey && document.activeElement === lastElement) {
      firstElement.focus()
      return e.preventDefault()
    } else if (e.shiftKey && document.activeElement === firstElement) {
      lastElement.focus()
      e.preventDefault()
    }
  }

  const toggle = (e: KeyboardEvent): void => {
    e.preventDefault()
    if (props.toggleModalFunction) {
      props.toggleModalFunction()
    }
  }

  const keyListenersMap = new Map([
    [27, toggle],
    [9, handleTabKey]
  ])

  const keyListener = (e: KeyboardEvent): any => {
    const listener = keyListenersMap.get(e.keyCode)
    return listener && listener(e)
  }

  return (
    <Background
      isFullScreen={props.isFullScreen}
      disableFullScreenMobile={props.disableFullScreenMobile}
      className='modal-background'
    >
      <ModalWindow {...props} role='dialog' aria-modal='true' ref={modalRef} className='modal-window'>
        <TopNavButtonsWrapper
          notificationCompensation={props.notificationCompensation}
        >
          {props.backButtonClicked && (
            <BackButton aria-label='Back' onClick={props.backButtonClicked}>
              <Icon color={colors.white} size={28} type={'back'} />
            </BackButton>
          )}

          {props.toggleModalFunction && (
            <CloseButton
              aria-label='Close'
              onClick={toggle}
              closeButtonHeight={props.closeButtonHeight}
            >
              <Icon
                type='x'
                size={19}
                color={props.invertColor ? colors.midnightBlue : colors.sand}
                backgroundColor='transparent'
              />
            </CloseButton>
          )}
        </TopNavButtonsWrapper>
        <ModalContents {...props}>
          {props.bgGradient && (
            <BackgroundImageContainer isFullScreen={props.isFullScreen}>
              <FadeInImage
                src={DipseaContentfulAssets.auth_modal_gradient}
                objectFit='cover'
                layout='fill'
                fadeInTime={0.3}
              />
            </BackgroundImageContainer>
          )}

          {props.children}
        </ModalContents>
      </ModalWindow>
    </Background>
  )
}

export default function ModalWrapper (props: PropsType): React.Node {
  // if the modal is open prevent the main body from scrolling
  React.useEffect((): void => {
    const body = document.querySelector('body')
    if (body && body.style) {
      body.style.overflow = props.isVisible ? 'hidden' : 'auto'
    }
  }, [props.isVisible])

  if (!props.isVisible) {
    return <></>
  } else {
    return <Modal {...props}>{props.children}</Modal>
  }
}
const TopNavButtonsWrapper = styled.div`
  z-index: 1002;
  ${(props: any): any => {
    if (props.notificationCompensation) {
      return 'top: 33px;'
    } else {
      return 'top: 0;'
    }
  }}
  ${(props: any): string => config(props).media.lg`
    @media (min-height: 700px) {
      top: 0;
    }
  `}
`
const Background = styled.div`
  background: ${colors.midnightBlue70};
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1001;
  ${(props: any): any => {
    if (props.disableFullScreenMobile) {
      return `
        align-items: center;
        display: flex;
        justify-content: center;
        padding: 20px;
      `
    }
  }}
  ${(props: any): string => config(props).media.md`
    @media (min-height: 700px) {
      align-items: center;
      display:flex;
      justify-content:center;
      padding: ${(props: any): any => (props.isFullScreen ? '0' : '20px')};
    }
  `}
`
const ModalWindow = styled.div`
  background-color: ${({ invertColor, modalCardBackgroundColor }: any): any =>
    modalCardBackgroundColor ||
    (invertColor ? colors.sand : colors.midnightBlue)};
  bottom: 0;
  color: ${({ invertColor }: any): any =>
    invertColor ? colors.midnightBlue : colors.white};
  display: flex;
  flex-direction: column;
  left: 0;
  overflow: ${(props: any): any => props.overflow || 'scroll'};
  overflow-x: hidden;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1002;
  ${(props: any): string => config(props).media.md`
    @media (min-height: 700px) {
      border-radius: ${(props: any): any =>
        props.isFullScreen ? '0' : '20px'};
      max-width: ${(props: any): any =>
        props.maxWidth ? `${props.maxWidth}px` : '594px'};
      max-height: ${(props: any): any =>
        props.isFullScreen ? '100vh' : '95vh'}; 
      position: relative;
      width: 100%;
    }
  `}

  ${(props: any): any => {
    if (props.disableFullScreenMobile) {
      return `
        border-radius: 20px;
        max-width: ${props.maxWidth ? `${props.maxWidth}px` : '594px'};
        max-height: 95vh;
        position: relative;
        width: 100%;
      `
    }
    if (props.isFullScreen) {
      return `
        height: 100%;
        max-height: 100vh;
        max-width: 100vw;
        min-width: 100vw;
        width: 100%;
      `
    }
  }}
`
const NavButton = styled.button`
  align-items: center;
  background-color: transparent;
  border: none;
  border-radius: ;
  cursor: pointer;
  display: flex;
  justify-content: center;
  margin: 0;
  outline: none;
  padding: 0;
  position: absolute;
  z-index: 1001;
  ${OutlineOnFocusVisible};
`
const CloseButton = styled(NavButton)`
  right: 22px;
  top: ${(props: any): any => props.closeButtonHeight ? `${props.closeButtonHeight}px;` : `22px;`};
`
const BackButton = styled(NavButton)`
  left: 8px;
  top: 20px;
  ${(props: any): string => config(props).media.md`
    left: 28px;
    top: 35px;
  `}
`
const ModalContents = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: center;
  margin: 0px;
  position: relative;
  ${(props: any): any => {
    if (props.notificationCompensation) {
      return 'margin-top: 33px;'
    }
  }}

  padding: env(safe-area-inset-top) 0 env(safe-area-inset-bottom) 0;
`

const BackgroundImageContainer = styled.div`
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`
