// @flow
import type {
  DipseaContentType,
  GetStripeChargeType, RecommendationV5Type, ScoredTracksType,
  SearchRecommendationType
} from '../flowTypes'
import axios from 'axios'
import { isDev, isStaging } from '../env'
import { currentUser } from '../services/firebaseAuth'
import type { ThemeObjectType } from '../models/ThematicLandingPage'

export function getStripeCharge (): Promise<GetStripeChargeType> {
  const devUrl = 'https://app-qa.dipseastories.com/getStripeCharges/v1'
  const prodUrl = 'https://app.dipseastories.com/getStripeCharges/v1'
  return axios({
    method: 'GET',
    withCredentials: true,
    url: isDev || isStaging() ? devUrl : prodUrl
  }).then((response: any): GetStripeChargeType => (response.data: GetStripeChargeType))
}

function getGlobal (key: string): any {
  if (typeof window !== 'undefined') {
    if (window[key]) {
      return window[key]
    }
  }
  if (global[key]) {
    return global[key]
  }
}

function setGlobal (key: string, data: any): any {
  if (typeof window !== 'undefined') {
    window[key] = data
  }
  global[key] = data
  return data
}

/**
 * Retrieves the Dipsea content library, sets a mutable global.contentLibrary for faster retrieval
 * @returns {Promise<unknown>}
 */
export async function getLibrary (): Promise<DipseaContentType> {
  if (getGlobal('contentLibrary')) {
    return getGlobal('contentLibrary')
  }
  let url = 'https://api.dipseastories.com/library?ver=16'
  if (process.env.NEXT_PUBLIC_APP_ENV === 'local') {
    url = 'https://us-central1-dipsea-test.cloudfunctions.net/dipseaapi/library?ver=16'
  }

  const contentLibrary = await axios({
    method: 'GET',
    url,
    responseType: 'json',
    headers: {
      dipsea: 'approved'
    }
  }).then((response: any): DipseaContentType => (response.data: DipseaContentType))
  const themes = await getThemes()
  contentLibrary.themes = themes
  setGlobal('contentLibrary', contentLibrary)
  return getGlobal('contentLibrary')
}

export async function getSearchRecommendations (userId?: string): Promise<SearchRecommendationType[]> {
  let url = `https://api.dipseastories.com/recommendations?ver=19&recommendationType=search${userId ? '&userId=' + userId : ''}`

  if (process.env.NEXT_PUBLIC_APP_ENV === 'local') {
    url = `https://us-central1-dipsea-test.cloudfunctions.net/dipseaapi/recommendations?ver=19&recommendationType=search${userId ? '&userId=' + userId : ''}`
  }
  const searchRecs: SearchRecommendationType[] = await axios({
    method: 'GET',
    url,
    responseType: 'json'
  }).then((response: any): SearchRecommendationType[] => (response.data.recommendations))
  return searchRecs
}

export async function getDiscoverRecommendations (): Promise<RecommendationV5Type[]> {
  const user = currentUser()
  let userId
  if (user) {
    userId = user.id
  }
  let url = `https://api.dipseastories.com/recommendations?ver=19${userId ? '&userId=' + userId : ''}`

  if (process.env.NEXT_PUBLIC_APP_ENV === 'local') {
    url = `https://us-central1-dipsea-test.cloudfunctions.net/dipseaapi/recommendations?ver=19&${userId ? '&userId=' + userId : ''}`
  }

  const recs: RecommendationV5Type[] = await axios({
    method: 'GET',
    url,
    responseType: 'json',
    headers: {
      dipsea: 'approved'
    }
  }).then((response: any): RecommendationV5Type[] => (response.data.recommendations))
  return recs
}

/**
 * Retrieves all tracks with a list of ranked tracks by similarity.
 * @returns {Promise<unknown>}
 */
export async function getScoredTracks (): Promise<ScoredTracksType> {
  const key = 'scoredTracks'
  if (getGlobal(key)) {
    return getGlobal(key)
  }

  let url = 'https://api.dipseastories.com/scored-tracks'

  if (process.env.NEXT_PUBLIC_APP_ENV === 'local') {
    url = `https://us-central1-dipsea-test.cloudfunctions.net/dipseaapi/scored-tracks`
  }

  const scoredTracks = await axios({
    method: 'GET',
    url,
    responseType: 'json',
    headers: {
      dipsea: 'approved'
    }
  }).then((response: any): ScoredTracksType => (response.data: ScoredTracksType))

  setGlobal(key, scoredTracks)
  return scoredTracks
}

export type PopularityBySeriesType = {
  series_id: string,
  completed_track_7_days: number,
  completed_track_6_months: number,
  completed_track_24_hours: number,
  completed_track_48_hours: number,
  all_time_track_plays: number
}

/**
 * Retrieves all series by popularity.
 * @returns {Promise<unknown>}
 */
export async function getPopularSeries (): Promise<PopularityBySeriesType[]> {
  const key = 'popularSeries'
  if (getGlobal(key)) {
    return getGlobal(key)
  }
  let url = 'https://api.dipseastories.com/popular-series'

  if (process.env.NEXT_PUBLIC_APP_ENV === 'local') {
    url = `https://us-central1-dipsea-test.cloudfunctions.net/dipseaapi/popular-series`
  }

  const series = await axios({
    method: 'GET',
    url,
    responseType: 'json',
    headers: {
      dipsea: 'approved'
    }
  }).then((response: any): PopularityBySeriesType[] => (response.data: PopularityBySeriesType[]))

  setGlobal(key, series)
  return series
}

export type PopularityByTrackRowType = {
  track_id: string,
  completed_track_24: number,
  completed_track_48: number,
  completed_track_7_days: number,
  completed_track_6_months: number,
  favorited_track_24: number,
  favorited_track_48: number,
  favorited_track_7_days: number,
  all_time_track_plays: number
}

/**
 * Retrieves all tracks by popularity.
 * @returns {Promise<unknown>}
 */
export async function getPopularTacks (): Promise<PopularityByTrackRowType[]> {
  const key = 'popularTracks'
  if (getGlobal(key)) {
    return getGlobal(key)
  }
  let url = 'https://api.dipseastories.com/popular-tracks'

  if (process.env.NEXT_PUBLIC_APP_ENV === 'local') {
    url = `https://us-central1-dipsea-test.cloudfunctions.net/dipseaapi/popular-tracks`
  }

  const series = await axios({
    method: 'GET',
    url,
    responseType: 'json',
    headers: {
      dipsea: 'approved'
    }
  }).then((response: any): PopularityByTrackRowType[] => (response.data: PopularityByTrackRowType[]))
  setGlobal(key, series)
  return series
}

/**
 * Retrieves all tracks by popularity.
 * @returns {Promise<unknown>}
 */
export async function getThemes (): Promise<ThemeObjectType> {
  const key = 'dipseaThemes'
  if (getGlobal(key)) {
    return getGlobal(key)
  }

  let url = 'https://api.dipseastories.com/library/themes'

  if (process.env.NEXT_PUBLIC_APP_ENV === 'local') {
    url = `https://us-central1-dipsea-test.cloudfunctions.net/dipseaapi/library/themes`
  }
  const series = await axios({
    method: 'GET',
    url,
    responseType: 'json',
    headers: {
      dipsea: 'approved'
    }
  }).then((response: any): ThemeObjectType => (response.data: ThemeObjectType))
  setGlobal(key, series)
  return series
}
