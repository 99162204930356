// @flow

import type { DipseaContentType, SerialType } from '../flowTypes'
import { getLibrary, getPopularSeries, getPopularTacks } from '../services/dipseaApi'
import { cloneDeep, intersection } from 'lodash'
import {
  convertTrackImages,
  convertTrackToProps,
  generateCharacterExperienceRowForSeries,
  reducePopularity
} from './Track'
import type { TrackPropsType, TrackImagesType, PopularityType } from './Track'
import { sortSlugs } from './helpers'
import type { DeepLinkedCardsCarouselPropsType, DeepLinkedCardsItemPropsType } from './Discover'
import type { PopularityBySeriesType } from '../services/dipseaApi'
import type { TagInfoType } from './Tag'
import { filterTag } from './Tag'
import type { ThematicTagInfoType } from './ThematicLandingPage'
import { generateSubThemeRowsForSeries, getAudioBookThematicTags, getUserQuotes } from './ThematicLandingPage'
import type { ReviewType } from '@components/ReviewSlider'

export async function getSeriesSlugsAsync (): Promise<string[]> {
  const library = await getLibrary()
  return Object.keys(library?.slugs?.serials).filter((slug: string): boolean => {
    const key = library?.slugs?.serials[slug]
    const series = library.series[key]
    if (!series) {
      return false
    }
    if (!validateSeries(series)) {
      return false
    }
    return !!series.publishedAt && Object.keys(series.track_ids || {}).length > 0
  })
}

export async function getSeriesBySlugAsync (slug: string): Promise<SerialType> {
  const library = await getLibrary()
  const seriesId = library?.slugs?.serials[slug]
  const series = cloneDeep(library?.series[seriesId] || {})
  return { ...series, id: seriesId }
}

export async function getSeriesByIdAsync (id: string): Promise<SerialType> {
  const library = await getLibrary()
  const series = cloneDeep(library.series[id])
  return { ...series, id }
}
export function validateSeries (series: SerialType): boolean {
  return (!!series && !!series.description && !!series.publishedAt && !!series.name && Object.keys(series.track_ids || {}).length > 0 && Object.keys(series.slugs || {}).length > 0)
}
export async function getAllSeriesAsync (): Promise<SerialType[]> {
  const library = await getLibrary()
  // $FlowFixMe
  const seriesIds: string[] = Object.keys(library.series)
  return seriesIds.filter((key: string): boolean => validateSeries(library.series[key]))
    .sort((a: string, b: string): number => library.series[a].index - library.series[b].index)
    .map((key: string): SerialType => ({ ...library.series[key], id: key }))
}

export function getFirstTrackImage (series: SerialType, library: DipseaContentType): TrackImagesType {
  const trackId = Object.keys(series.track_ids).sort((a: string, b: string): number => {
    return series.track_ids[a].index - series.track_ids[b].index
  })[0]
  const track = library.tracks[trackId]
  const images = convertTrackImages(track)
  return images
}

export function getFirstTrack (series: SerialType, library: DipseaContentType, popularityObj: PopularityType = {}): TrackPropsType | null {
  try {
    const trackId = Object.keys(series.track_ids).filter((tagId: string): boolean => !filterTag(tagId)).sort((a: string, b: string): number => {
      return series.track_ids[a].index - series.track_ids[b].index
    })[0]
    const track = library.tracks[trackId]
    const trackProps = convertTrackToProps(track, library, popularityObj)
    return trackProps
  } catch (e) {
    return null
  }
}
export function getFirstTags (series: SerialType, library: DipseaContentType): TagInfoType[] {
  try {
    const trackId = Object.keys(series.track_ids).filter((tagId: string): boolean => !filterTag(tagId)).sort((a: string, b: string): number => {
      return series.track_ids[a].index - series.track_ids[b].index
    })[0]
    const track = library.tracks[trackId]
    const trackProps = convertTrackToProps(track, library)
    return trackProps.tags
  } catch (e) {}
  return []
}

export function getFirstTrackImageWithLibrary (series: SerialType, library: DipseaContentType): TrackImagesType {
  const trackId = Object.keys(series.track_ids).sort((a: string, b: string): number => {
    return series.track_ids[a].index - series.track_ids[b].index
  })[0]
  const track = library.tracks[trackId]
  const images = convertTrackImages(track)
  return images
}

export function getEpisodeInformation (series: SerialType): string {
  const episodeLabel = series.episodesLabel || 'Chapter'
  const totalStories = Object.keys(series.track_ids).length
  return `${totalStories} ${totalStories === 1 ? episodeLabel : episodeLabel + 's'}`
}

export type SeriesInfoType = {id: string, slug: string, title: string, episodeInfo: string, description: string, imgUrl: string}

export function getSeriesInfoProps (series: SerialType, library: DipseaContentType): SeriesInfoType {
  const images = getFirstTrackImageWithLibrary(series, library)
  let slug = sortSlugs(series.slugs)[0]
  if (Object.keys(series.track_ids).length < 3) {
    slug = slug + '?h=2'
  }
  return {
    id: series.id,
    slug: '/audiobooks/' + slug,
    title: series.name,
    episodeInfo: getEpisodeInformation(series),
    description: series.description,
    imgUrl: images.img500
  }
}

export type SeriesInfoPropsType = {
  title: string,
  description: string,
  slug: string,
  id: string,
  episodeInfo: string,
  publishedAt: number,
  tags: TagInfoType[],
  images: {
    ['img100' | 'img500' | 'img800' | 'img1000']: string
  },
  popularity: number | null,
  thematicTags?: ThematicTagInfoType[]
}
export type SeriesFeaturedType = {
  title: string, subtitle: string, imageUrl: string
}

export type SeriesPropsType = {
  ...SeriesInfoPropsType,
  featured?: SeriesFeaturedType | null,
  tracks: TrackPropsType[],
  themeRows: DeepLinkedCardsCarouselPropsType[],
  characterExperiences: DeepLinkedCardsCarouselPropsType[],
  quotes: ReviewType[]
}

export function convertSeriesFeatured (series: SerialType): SeriesFeaturedType | null {
  if (series.featured) {
    return {
      title: series.featured.header,
      subtitle: series.featured.subheader,
      imageUrl: series.featured.image
    }
  }
  return null
}
export function convertSeriesToProps (series: SerialType, library: DipseaContentType, popularityMap: PopularityType): SeriesPropsType {
  const images = getFirstTrackImage(series, library)
  let slug = sortSlugs(series.slugs)[0]
  const tracks = getSeriesTracks(series, library, popularityMap)
  const tags = getFirstTags(series, library)
  if (tracks.length < 3) {
    slug = slug + '?h=2'
  }
  return {
    id: series.id,
    publishedAt: series.publishedAt,
    title: series.name,
    description: series.description,
    images,
    episodeInfo: getEpisodeInformation(series),
    tags,
    slug: '/audiobooks/' + slug,
    tracks,
    popularity: null,
    thematicTags: getAudioBookThematicTags(series.id, library),
    themeRows: [],
    characterExperiences: [],
    quotes: [],
    featured: convertSeriesFeatured(series)
  }
}

export function convertSeriesToSeriesInfoProps (series: SerialType, library: DipseaContentType, popularity: number = 0): SeriesInfoPropsType {
  const images = getFirstTrackImage(series, library)
  let slug = sortSlugs(series.slugs)[0]
  const tags = getFirstTags(series, library)
  const trackLength = Object.keys(series.track_ids || {}).length
  if (trackLength < 3) {
    slug = slug + '?h=2'
  }
  return {
    id: series.id,
    title: series.name,
    description: series.description.length >= 150 ? series.description.slice(0, 150).trim() + '...' : series.description,
    publishedAt: series.publishedAt,
    images,
    episodeInfo: getEpisodeInformation(series),
    tags,
    slug: '/audiobooks/' + slug,
    popularity,
    thematicTags: getAudioBookThematicTags(series.id, library)
  }
}

export async function convertSeriesByIdToProps (id: string): Promise<SeriesPropsType> {
  const [library, popularity, seriesPopularity] = await Promise.all([
    getLibrary(),
    getPopularTacks(),
    getPopularSeries()
  ])
  const seriesProps = convertSeriesToProps(library.series[id], library, reducePopularity(popularity, seriesPopularity))
  const subThemes = await generateSubThemeRowsForSeries(id)
  const characterExperiences = generateCharacterExperienceRowForSeries(seriesProps, library)
  seriesProps.themeRows = subThemes || []
  seriesProps.characterExperiences = characterExperiences || []
  return seriesProps
}

export async function convertSeriesBySlugToProps (slug: string): Promise<SeriesPropsType> {
  const [library, popularity, seriesPopularity, quotes] = await Promise.all([
    getLibrary(),
    getPopularTacks(),
    getPopularSeries(),
    getUserQuotes(slug)
  ])
  const seriesId = library.slugs.serials[slug]
  const series = library.series[seriesId]
  const seriesProps = convertSeriesToProps({ ...series, id: seriesId }, library, reducePopularity(popularity, seriesPopularity))
  const subThemes = await generateSubThemeRowsForSeries(seriesId)
  const characterExperiences = generateCharacterExperienceRowForSeries(seriesProps, library)
  seriesProps.themeRows = subThemes || []
  seriesProps.characterExperiences = characterExperiences || []
  seriesProps.quotes = quotes || []
  return seriesProps
}

export function sortSeriesTracks (series: SerialType): string[] {
  return Object.keys(series.track_ids).sort((a: string, b: string): number => {
    return series.track_ids[a].index - series.track_ids[b].index
  })
}

export function getSeriesTracks (series: SerialType, library: DipseaContentType, popularityMap: PopularityType): TrackPropsType[] {
  if (!series) {
    return []
  }
  const trackIds = sortSeriesTracks(series)
  return trackIds
    .map((trackId: string): TrackPropsType => convertTrackToProps({ id: trackId, ...library.tracks[trackId] }, library, popularityMap))
    .filter((track: TrackPropsType | null): boolean => !!track)
}

export async function getSeriesSlugAsync (id: string): Promise<string> {
  const library = await getLibrary()
  const series = library?.series[id]
  return '/audiobooks/' + sortSlugs(series.slugs)[0]
}

export function convertSeriesIntoDeepCardCarouselProps (series: SerialType, library: DipseaContentType): DeepLinkedCardsItemPropsType {
  const imageUrl = getFirstTrackImageWithLibrary(series, library)
  const tags = getFirstTags(series, library)
  const trackLength = Object.keys(series.track_ids || {}).length
  const description2 = trackLength > 1 ? `${trackLength} Chapters` : `${trackLength} Chapter`
  let slug = sortSlugs(series.slugs)[0]
  if (trackLength < 5) {
    slug = slug + '?h=2'
  }
  return {
    title: series.name,
    slug: '/audiobooks/' + slug,
    imageUrl: imageUrl?.img800,
    badgeText: null,
    description: series.description,
    description2,
    tags
  }
}

export type SeriesListPropsType = {
  title: string,
  description: string,
  series: SeriesInfoPropsType[]
}

function sortSeries (series: SeriesInfoPropsType[]): SeriesInfoPropsType[] {
  const sortedSeries = series.sort((a: SeriesInfoPropsType, b: SeriesInfoPropsType): number => (b.popularity || 0) - (a.popularity || 0))
  const newSeries = []
  const oldSeries = []
  sortedSeries.forEach((series: SeriesInfoPropsType, index: number): void => {
    if (series.publishedAt > Date.now() - 30 * 24 * 60 * 60 * 1000) {
      newSeries.push(series)
    } else {
      oldSeries.push(series)
    }
  })
  return newSeries.concat(oldSeries)
}

export async function getSeriesListProps (): Promise<SeriesListPropsType> {
  const [series] = await Promise.all([getPopularSeriesProps(), getLibrary()])
  return {
    title: 'Top Audiobooks',
    description: 'Explore our audiobooks',
    series: sortSeries(series)
  }
}

export async function getPopularSeriesProps (): Promise<SeriesInfoPropsType[]> {
  const [popularity, library] = await Promise.all([getPopularSeries(), getLibrary()])
  const popularityMap = popularity.reduce((acc: {[key: string]: number}, track: PopularityBySeriesType, index: number): {[key: string]: number} => {
    acc[track.series_id] = track.completed_track_6_months
    return acc
  }, {})
  const serials = Object.keys(library.series || {}).filter((seriesId: string): boolean => validateSeries(library.series[seriesId]))
    .map((seriesId: string): SeriesInfoPropsType => convertSeriesToSeriesInfoProps({ ...library?.series[seriesId], id: seriesId }, library, popularityMap[seriesId] || 0))
  return serials.sort((a: SeriesInfoPropsType, b: SeriesInfoPropsType): number => (b.popularity || 0) - (a.popularity || 0))
}

export function seriesHasTags (tags: string[], series: SeriesInfoPropsType): boolean {
  if (!tags.length) {
    return true
  }
  const tagIds = series.tags.map((tag: TagInfoType): string => tag.id)
  return intersection(tagIds, tags).length === tags.length
}
