// @flow
export type ColorsType =
  | 'midnightBlue'
  | 'violet'
  | 'magenta'
  | 'cobalt'
  | 'blue'
  | 'orange'
  | 'creamsicle'
  | 'sand'
  | 'white'
  | 'pink'

export const colors = {
  peach: '#EFBC9F',
  violet: 'rgba(84, 14, 51, 1)',
  violet0: 'rgba(84, 14, 51, 0)',
  violet90: 'rgba(84, 14, 51, 0.9)',
  violet95: 'rgba(84, 14, 51, 0.95)',
  magenta: '#AE004E',
  cobalt: 'rgba(41, 24, 128, 1)',
  cobalt0: 'rgba(41, 24, 128, 0)',
  cobalt90: 'rgba(41, 24, 128, 0.9)',
  cobalt95: 'rgba(41, 24, 128, 0.95)',
  blue: '#372CBC',
  red: '#FF0000',
  creamsicle: '#FF883E',
  orange: 'rgba(242, 71, 42, 1)',
  orange0: 'rgba(242, 71, 42, 0)',
  orange25: 'rgba(242, 71, 42, 0.25)',
  orange90: 'rgba(242, 71, 42, 0.9)',
  orange95: 'rgba(242, 71, 42, 0.95)',
  tintOrange: 'rgba(255, 102, 77, 1)',
  sand: '#E3D0C5',
  sand20: 'rgba(226, 218, 207, 0.20)',
  sand25: 'rgba(226, 218, 207, 0.25)', // #e3dbcf
  sand60: 'rgba(226, 218, 207, 0.60)', // #e1dacf
  sand70: 'rgba(226, 218, 207, 0.70)',
  sand90: 'rgba(226, 218, 207, 0.90)',
  white: '#FFFFFF',
  white10: 'rgba(255, 255, 255, 0.10)',
  white15: 'rgba(255, 255, 255, .15)',
  white25: 'rgba(255, 255, 255, .25)',
  white50: 'rgba(255, 255, 255, 0.5)',
  white60: 'rgba(255, 255, 255, 0.6)',
  white70: 'rgba(255, 255, 255, 0.7)',
  white80: 'rgba(255, 255, 255, 0.8)',
  white90: 'rgba(255, 255, 255, 0.9)',
  black: '#000000',
  midnightBlue: 'rgba(38, 18, 47, 1)',
  midnightBlue0: 'rgba(38, 18, 47, 0)',
  midnightBlue60: 'rgba(38, 18, 47, 0.6)',
  midnightBlue70: 'rgba(38, 18, 47, 0.7)',
  midnightBlue90: 'rgba(38, 18, 47, 0.9)',
  midnightBlue95: 'rgba(38, 18, 47, 0.95)',
  transparent: 'transparent',
  formFill: '#22203e',
  pink: '#FF95AB',
  midnightBlueTint: '#311E39',
  accentViolet: '#540E33',
  accentMagenta: '#AE004E',
  accentCobalt: '#291880',
  accentBlue: '#372CBC',
  accentCreamsicle: '#FF883E',
  accentPink: '#FF95AB',
  darkestBlue: '#0b0826',
  darkPurple: '#1E165E',
  gray: '#888888',
  green: '#004646',
  darkGreen: '#003B38',
  lightCobalt: '#534F6B',
  lightGray: '#D7D5D5',
  lightPurple: '#7C77B9',
  purple: '#51348F',
  yellow: '#FFA83F',
  blueishPurple: '#382A9B',
  secondaryBlue: '#9D96E8',
  translucentCobalt: 'rgba(34, 26, 116, 0.7)',
  translucentCobaltLight: 'rgba(34, 26, 116, 0.1)',
  translucentDarkPurple: 'rgba(30, 22, 94, 0.5)',
  translucentBlack: 'rgba(0, 0, 0, 0.1)'
}
