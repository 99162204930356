// @flow
import * as React from 'react'
import Router from 'next/router'
import { isServer } from '../env'
import { logPageSegment } from '../services/segment'
import { ROUTE_CHANGE_START, ROUTE_CHANGE_COMPLETE } from './actionTypes'
import { trackPageViewSnapChat } from '../services/snapchat'
import type { UtmParamsType } from '../flowTypes'
import { toggleSubscriptionModal, clearPromoCode } from './subscriptionActions'
import Link from 'next/link'

export function listenToRouterEvents (): any {
  return async (dispatch: any): Promise<void> => {
    if (!isServer) {
      if (!window.routeChangeStart) {
        window.routeChangeStart = Router.events.on(
          'routeChangeStart',
          (url: string): void => {
            dispatch({
              type: ROUTE_CHANGE_START
            })
          }
        )
      }
      if (!window.routeChangeComplete) {
        window.routeChangeComplete = Router.events.on(
          'routeChangeComplete',
          (url: string): void => {
            logPageSegment(url)
            trackPageViewSnapChat()
            dispatch({
              type: ROUTE_CHANGE_COMPLETE
            })
          }
        )
      }
    }
  }
}

export function getUrlWithCleanUtms (pathname: string): any {
  let search
  if (!isServer) {
    search = window?.location?.search
  }

  if (search) {
    if (pathname.includes('?')) {
      search = search.replace('?', '&')
    }

    const keepUtms = ['utm_campaign', 'utm_medium', 'utm_source', 'utm_content', 'utm_term', 'campaignId', 'templateId', 'irclickid', 'o_auth', 'promo']
    const searchArray = search.split(/[?&]+/) // split by ? or &
    const currentKeys = []
    let cleanUtms = ''
    if (searchArray.length) {
      searchArray.forEach((item: string): void => {
        const key = item.split('=')[0]
        if (keepUtms.includes(key)) {
          if (currentKeys.includes(key)) {
            // it's a duplicate, don't add, keep first one
          } else {
            // save key
            currentKeys.push(key)

            if (pathname.includes('?') || cleanUtms.length) {
            // add utm item
              cleanUtms += '&' + item
            } else {
            // set first utm item
              cleanUtms = '?' + item
            }
          }
        }
      })
    }

    if (cleanUtms.length) {
      return `${pathname}${cleanUtms}`
    }
  }

  return pathname
}

export function pushWithUtms (pathname: string): any {
  return async (dispatch: any): Promise<void> => {
    const url = getUrlWithCleanUtms(pathname)
    Router.push(url)
  }
}

export function LinkWithUtms (props: {
  pathname: string,
  children: React.Node | string,
  target?: string
}): React.Node {
  const { pathname, children, target } = props
  const url = getUrlWithCleanUtms(pathname)
  return (
    <Link href={url} passHref style={{ textDecoration: 'none' }} target={target || null}>
      {children}
    </Link>
  )
}

export function openBlogPage (page: string): any {
  return async (dispatch: any): Promise<void> => {
    dispatch(pushWithUtms(`/blog/${page}`))
  }
}

export function openBlog (): any {
  return async (dispatch: any): Promise<void> => {
    dispatch(pushWithUtms('/blog'))
  }
}

export function openOurStory (sectionId?: string): any {
  return async (dispatch: any): Promise<void> => {
    dispatch(pushWithUtms('/about'))
    if (sectionId) {
      setTimeout((): void => {
        Router.push(`#${sectionId}`)
      }, 1000)
    }
  }
}

export function openSubscribe (promo?: string): any {
  return async (dispatch: any): Promise<void> => {
    let url = '/subscribe'
    if (promo) {
      url += `?promo=${promo}&f=2`
    }
    await dispatch(pushWithUtms(url))
    dispatch(clearPromoCode())
  }
}

export function openDiscover (): any {
  return async (dispatch: any): Promise<void> => {
    await dispatch(pushWithUtms('/discover'))
  }
}

export function openSubscribeConfirmed (isDark: boolean = false): any {
  return async (dispatch: any): Promise<void> => {
    const pathname = `/subscribe/confirmed${isDark ? '?isDark=true' : ''}`
    dispatch(pushWithUtms(pathname))
  }
}

export function openGift (): any {
  return async (dispatch: any): Promise<void> => {
    dispatch(pushWithUtms('/gift'))
  }
}

export function openHome (): any {
  return async (dispatch: any): Promise<void> => {
    dispatch(pushWithUtms('/'))
  }
}

export function openGiftConfirmPage (): any {
  return async (dispatch: any): Promise<void> => {
    dispatch(pushWithUtms(`/gift/confirmed`))
  }
}

export function openRedeemConfirmPage (): any {
  return async (dispatch: any): Promise<void> => {
    dispatch(pushWithUtms(`/redeem/confirmed`))
  }
}

export function openListen (): any {
  return async (dispatch: any): Promise<void> => {
    dispatch(pushWithUtms(`/listen`))
  }
}

export function signIn (utmParams: UtmParamsType = {}): any {
  return async (dispatch: any): Promise<void> => {
    dispatch(pushWithUtms(`/account`))
  }
}

export function openSubscribeModal (): any {
  return async (dispatch: any): Promise<void> => {
    dispatch(toggleSubscriptionModal())
  }
}
